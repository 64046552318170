@tailwind base;
@tailwind components;
@tailwind utilities;


.carousel .control-dots .dot {
    margin-top: 2rem;
    background-color: #feca8e !important; /* Primary Color */
    opacity: 1 !important;
  }
  
  .carousel .control-dots .dot.selected {
    margin-top: 2rem;
    background-color: #1a1819 !important; /* Secondary/Gray Color */
  }
  